<template>
  <layout width="420">
    <template slot="header">Change password</template>
    <validation-observer ref="form">
      <v-form v-model="valid" autocomplete="off" lazy-validation>
        <validation-provider
          v-slot="{ errors }"
          :debounce="500"
          name="password"
          vid="password"
          rules="required|checkPassword"
        >
          <v-text-field
            v-model="newPassword"
            label="New password"
            autocomplete="off"
            :error-messages="errors"
            :append-icon="newPasswordType ? 'mdi-eye' : 'mdi-eye-off'"
            :type="newPasswordType ? 'password' : 'text'"
            clearable
            @click:append="() => (newPasswordType = !newPasswordType)"
          />
        </validation-provider>
        <validation-provider
          v-slot="{ errors }"
          vid="repeatPassword"
          name="Repeat password"
          rules="required|confirmed:password"
        >
          <v-text-field
            v-model="newPasswordRepeat"
            autocomplete="new-password"
            :error-messages="errors"
            :append-icon="newPasswordRepeatType ? 'mdi-eye' : 'mdi-eye-off'"
            :type="newPasswordRepeatType ? 'password' : 'text'"
            label="Repeat password"
            clearable
            class="mb-2"
            @click:append="
              () => (newPasswordRepeatType = !newPasswordRepeatType)
            "
          />
        </validation-provider>
      </v-form>
    </validation-observer>
    <template slot="footer">
      <v-spacer />
      <v-btn text @click="$emit('close')">Cancel</v-btn>
      <v-btn color="primary" text :loading="loading" @click="saveHandler">
        save
      </v-btn>
    </template>
  </layout>
</template>

<script>
import Layout from '@/components/popup/PopupLayoutDefault';
import { reactive, ref, toRefs } from '@vue/composition-api';
import { useApolloClient, useMutation } from '@vue/apollo-composable';
import { confirmed, required } from 'vee-validate/dist/rules';
import { extend, ValidationObserver, ValidationProvider } from 'vee-validate';
import schema from '@/modules/account/schema.graphql';

extend('required', {
  ...required,
  message: 'This field is required'
});

extend('confirmed', {
  ...confirmed,
  message: 'Passwords must be the same'
});

export default {
  name: 'EditPassword',
  components: { Layout, ValidationProvider, ValidationObserver },
  props: {
    userId: {
      type: String,
      required: true
    },
    loading: {
      type: Boolean,
      default: false
    }
  },
  setup(props, { emit }) {
    const { mutate: updateUser } = useMutation(schema.updateUser);
    const { resolveClient } = useApolloClient();
    const client = resolveClient();
    const form = ref(null);
    const loading = ref(false);

    extend('checkPassword', {
      validate: async value => {
        const { data } = await client.query({
          query: schema.validatePasswordChange,
          variables: {
            userId: props.userId,
            newPassword: value
          }
        });

        const response = JSON.parse(data.validatePasswordChange);

        if (response.valid) {
          return true;
        }

        return response.comment;
      }
    });

    let state = reactive({
      valid: false,
      errors: {
        newPassword: '',
        newPasswordRepeat: ''
      },
      newPassword: '',
      newPasswordType: true,
      newPasswordRepeat: '',
      newPasswordRepeatType: true
    });

    const saveHandler = async () => {
      form.value.validate().then(success => {
        if (!success) {
          return;
        }
        loading.value = true;

        updateUser({
          input: {
            id: props.userId,
            patch: {
              password: state.newPassword
            }
          }
        })
          .then(() => {
            emit('close');
          })
          .finally(() => {
            loading.value = false;
          });
      });
    };

    return {
      ...toRefs(state),
      saveHandler,
      form
    };
  }
};
</script>
